


































































































































































































































































  import { debounceInput } from '@/shared/helpers'
  import { Certificate } from '@/shared/model/certificate.model'
  import { CertificationAuthority, CertificationAuthorityRole } from '@/shared/model/certificationAuthority.model'
  import { IUser, User } from '@/shared/model/user.model'
  import certificationService from '@/shared/services/certificationService'
  import userService from '@/shared/services/userService'
  import Vue, { PropType } from 'vue'
  import { mapActions, mapGetters } from 'vuex'

interface SelectedUser {
  user: IUser
  certificateCreated: boolean
}

  export default Vue.extend({
    props: {
      preSelectedUsers: {
        type: Array as PropType<Array<IUser>>,
        default: undefined,
      },
    },
    data () {
      return {
        certificate: new Certificate(),
        tab: 0,
        tabs: [this.$t('data').toString(), this.$t('details').toString(), this.$t('result')],
        userAutocomplete: '',
        debounce: undefined as number | undefined,
        selectedCoworker: '' as string,
        datePickerVisible: false,
        selectedDate: undefined as Date | undefined,
        certificationAuthorityAutocomplete: undefined as CertificationAuthority | undefined,
        evalIdInput: '',
        loading: false,
        pdfFile: null as any,
        selectedUsers: [] as SelectedUser[],
        uploadProgress: 0,
        progressText: '',
      }
    },
    computed: {
      ...mapGetters({
        account: 'account',
        users: 'userStore/getList',
        certificateTemplates: 'certificateTemplateStore/getList',
        certificateError: 'certificateStore/getError',
        certificationAuthority: 'selectedCertificationAuthority',
        certificationAuthorities: 'certificationAuthorities',
      }),
      availableSteps () {
        const steps = [0]

        if (this.certificate.name && this.certificate.certificateTemplateObject) {
          steps.push(1)
        }

        if (this.selectedUsers.length && steps.includes(1)) {
          steps.push(2)
        }
        if ((this.uploadProgress === 100 || this.certificateError) && steps.includes(2)) {
          steps.push(3)
        }

        return steps
      },
      maxDate () {
        const now = new Date()
        const date5Years = new Date()
        date5Years.setFullYear(now.getFullYear() + 5)
        return date5Years.toISOString()
      },
      userIsSystemAdmin () {
        return userService.userIsSystemAdmin()
      },
      selectedUsersString (): string {
        const string = this.selectedUsers.map((x) => Vue.filter('fullNameOfUser')(x.user)).join(', ')
        return string
      },
      filteredCertAuthorities (): any {
        if (this.userIsSystemAdmin) {
          return this.certificationAuthorities
        } else if (this.certificationAuthorities) {
          const filteredCertAuthorities = []
          for (const certAuth of this.certificationAuthorities) {
            if (!this.userRoleInCertAuthIsReadOnly(certAuth)) {
              filteredCertAuthorities.push(certAuth)
            }
          }

          return filteredCertAuthorities
        } else {
          return []
        }
      },
    },
    watch: {
      userAutocomplete: {
        async handler () {
          this.debounce = debounceInput(this.debounce, this.getAllUsersForAutocomplete)
        },
      },
      certificationAuthorityAutocomplete: {
        async handler () {
          if (this.certificationAuthorityAutocomplete && this.certificationAuthorityAutocomplete._id) {
            certificationService.getCertificateTemplates(this.$store, this.certificationAuthorityAutocomplete._id)
          }
        },
      },
      certificateTemplates: {
        async handler () {
          this.certificate.certificateTemplateObject = this.certificateTemplates[0]
          if (this.certificate.certificateTemplateObject && this.certificate.certificateTemplateObject.certificateName) {
            this.certificate.name = this.certificate.certificateTemplateObject.certificateName
          } else {
            this.certificate.name = ''
          }
        },
      },
      'certificate.certificateTemplateObject': {
        async handler () {
          if (this.certificate.certificateTemplateObject && this.certificate.certificateTemplateObject.certificateName) {
            this.certificate.name = this.certificate.certificateTemplateObject.certificateName
          } else {
            this.certificate.name = ''
          }
        },
      },
      pdfFile: {
        async handler () {
          if (this.pdfFile) {
            var reader = new FileReader()
            reader.readAsDataURL(this.pdfFile)
            reader.onload = () => {
              if (reader.result) {
                this.certificate.pdfFile = reader.result.toString()
              }
            }
          } else {
            this.certificate.pdfFile = undefined
          }
        },
      },
    },
    created () {
      this.initSelectedCertAuth()

      if (this.account && this.account.language) {
        this.certificate.language = this.account.language
      }
      if (this.userIsSystemAdmin) {
        certificationService.getCertificationAuthorities().then((result: CertificationAuthority[]) => {})
      }
      if (this.preSelectedUsers) {
        for (let user of this.preSelectedUsers) {
          if(user.generatedPassword){
            // when users are selected from admin user-table(DataTable)
            user.generatedPassword = undefined
          }
        }
        this.selectedUsers = this.preSelectedUsers.map((x: IUser) => {
          return { user: x, certificateCreated: false }
        })
      }
    },
    methods: {
      ...mapActions('certificateStore', {
        createCertificate: 'createItem',
        updateCertificate: 'updateItem',
      }),
      async next (valid: any) {
        if (this.tab === this.tabs.length - 2 && this.selectedUsers && this.selectedUsers.length) {
          this.tab++
          await this.uploadCertificates()
        } else if (this.tab === this.tabs.length - 1) {
          this.wizardFinished()
        } else {
          this.tab++
        }
      },
      userRoleInCertAuthIsReadOnly (certAuth: CertificationAuthority) {
        if (certAuth.users) {
          const userFromCertAuth = certAuth.users.find((x: any) => x.user._id === this.account._id)
          if (userFromCertAuth && userFromCertAuth.authorityRoles) {
            if (
              userFromCertAuth.authorityRoles.includes(CertificationAuthorityRole.trainer) ||
              userFromCertAuth.authorityRoles.includes(CertificationAuthorityRole.authorityAdmin)
            ) {
              return false
            }
          }
        }
        return true
      },
      initSelectedCertAuth () {
        if (this.certificationAuthority && this.certificationAuthority._id) {
          if (!this.userRoleInCertAuthIsReadOnly(this.certificationAuthority)) {
            this.certificationAuthorityAutocomplete = this.certificationAuthority
            certificationService.getCertificateTemplates(this.$store, this.certificationAuthority._id)
          } else if (this.filteredCertAuthorities.length) {
            // pre-select a certAuth where user is not RO
            this.certificationAuthorityAutocomplete = this.filteredCertAuthorities[0]
            certificationService.getCertificateTemplates(this.$store, this.filteredCertAuthorities[0]._id)
          }
        } else {
          this.$waitForStoreMutation('setSelectedCertificationAuthority').then((result) => {
            this.initSelectedCertAuth()
          })
        }
      },
      async getAllUsersForAutocomplete () {
        const query = {
          l: 10,
          lo: 0,
          sf: 'username',
          so: 'desc',
          search: { search: this.userAutocomplete },
        }
        await userService.getAllUsersPaginated(query)
      },
      addUserByEvalId () {
        userService
          .getUserByEvalId(this.evalIdInput)
          .then((result) => {
            if (result.status === 200) {
              if (!this.selectedUsers.find((x) => x.user._id === result.data._id)) {
                this.selectedUsers.push({ user: result.data, certificateCreated: false })
              }
              this.evalIdInput = ''
            }
          })
          .catch((e) => {
            this.$toast.error(this.$t('dialog.user-with-this-eval-id-was-not-found').toString())
          })
      },
      async uploadCertificates () {
        this.loading = true
        for (let i = 0; i < this.selectedUsers.length; i++) {
          if (!this.selectedUsers[i].certificateCreated) {
            this.certificate.userObject = this.selectedUsers[i].user
            this.certificate.user = this.selectedUsers[i].user._id

            this.progressText = `${i + 1}/${this.selectedUsers.length} - ${Vue.filter('fullNameOfUser')(
              this.selectedUsers[i].user,
            )}`

            this.setCertificateData()
            await this.createCertificate({
              item: this.certificate,
            })
            if (this.certificateError) {
              if (this.certificateError) {
                break
              }
            } else {
              this.selectedUsers[i].certificateCreated = true
              this.uploadProgress = (100 / this.selectedUsers.length) * (i + 1)
            }
          }
        }
        this.uploadProgress = 100

        this.loading = false
      },
      setCertificateData () {
        if (this.certificationAuthorityAutocomplete && this.account) {
          if (!this.certificate.authorObject) {
            this.certificate.authorObject = this.account
          }
          if (this.certificate.authorObject) {
            this.certificate.author = this.certificate.authorObject._id
          }

          this.certificate.certificationAuthority = this.certificationAuthorityAutocomplete._id
          this.certificate.certificationAuthorityObject = this.certificationAuthorityAutocomplete
        }
        if (this.certificate.certificateTemplateObject) {
          this.certificate.certificateTemplate = this.certificate.certificateTemplateObject._id
        }
        this.setCertificateDueDate()
      },
      setCertificateDueDate () {
        const dueDate = new Date(this.certificate.date)
        if (this.certificate.certificateTemplateObject && this.certificate.certificateTemplateObject.validForYears) {
          dueDate.setFullYear(dueDate.getFullYear() + this.certificate.certificateTemplateObject.validForYears)
          this.certificate.dueDate = dueDate
        }
      },
      selectDate () {
        this.datePickerVisible = false
        if (this.selectedDate) {
          this.certificate.date = this.selectedDate
          this.setCertificateDueDate()
        }
      },
      wizardFinished () {
        if (this.$route.path === '/app/certificate/new') {
          this.$router.push('/app/certificates')
        }
        this.$emit('wizardfinished')
      },
    },
  })
