import { ICertificateTemplate } from './certificateTemplate'
import { IUser } from './user.model'

export enum CertificateType {
  CERT_A = 'CERT_A',
}

export interface ICertificate {
  _id?: string
  name?: string
  certificateNumber?: string
  date?: Date
  dueDate?: Date
  user?: string
  userObject?: IUser
  author?: string
  authorObject?: IUser
  certificationAuthority?: string
  certificationAuthorityObject?: IUser
  certificateTemplate?: string
  certificateTemplateObject?: ICertificateTemplate
  type?: CertificateType
  language?: string
  infos?: string[]
  pdfFile?: string
}

export class Certificate implements ICertificate {
  constructor (
    public name: string = '',
    public certificateNumber?: string,
    public date: Date = new Date(),
    public dueDate?: Date,
    public user?: string,
    public userObject?: IUser,
    public author?: string,
    public authorObject?: IUser,
    public certificationAuthority?: string,
    public certificationAuthorityObject?: IUser,
    public certificateTemplate?: string,
    public certificateTemplateObject?: ICertificateTemplate,
    public type?: CertificateType,
    public language?: string,
    public infos?: string[],
    public pdfFile?: string,
  ) {
    return this
  }
}
